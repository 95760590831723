import React from 'react'

const error = () => {
    return (
        <div>
            Page does not exist.
        </div>
    )
}

export default error
