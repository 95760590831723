import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import logo from "../static/images/adastra.svg"
import hero from "../static/images/head-hero.svg"
import psihoterapija from "../static/images/halfspace.svg"
import pristup from "../static/images/pristup.svg"
import oMeni from "../static/images/headespace.svg"
import "../styles/main.css"
import "../styles/noscript.css"

export const query = graphql`
  query {
    contentfulNaslovnicaZastoPsihoterapijaRichTextNode {
      json
    }
    contentfulNaslovnicaMojPristupRichTextNode {
      json
    }
    contentfulNaslovnicaOMeniTekstRichTextNode {
      json
    }
    contentfulNaslovnica {
      prviCitat
      mojPristupCitat
      glavniNaslov
      drugiCitat
    }
  }
`

const IndexPage = props => {
  return (
    <>
      <Helmet>
        <script src="./jquery.min.js" async={false} />
        <script src="./jquery.scrolly.min.js" async={false} />
        <script src="./jquery.scrollex.min.js" async={false} />
        <script src="./browser.min.js" async={false} />
        <script src="./breakpoints.min.js" async={false} />
        <script src="./util.js" async={false} />
        <script src="./main.js" async={false} />
        <title>Psihoterapija i savjetovanje - Zagreb | Ad Astra</title>
        <meta
          name="description"
          content="Mnogo je razloga za krenuti na psihoterapiju, a zajednički faktor je često osjećaj da smo zapeli – u negativnom ponašanju, emocijama, mislima ili načinu kako se povezujemo s drugima. "
        />
      </Helmet>
      <main id="home" className="is-preload">
        <div id="wrapper">
          <header id="header" className="alt">
            <a href="index.html" className="logo">
              <img src={logo} alt="ad astra logo" />
            </a>
            <nav>
              <a className="hamburger" href="#menu"></a>
            </nav>
          </header>

          <nav id="menu">
            <ul className="links">
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#psihoterapija">Psihoterapija</a>
              </li>
              <li>
                <a href="#moj-pristup">Moj pristup</a>
              </li>
              <li>
                <a href="#o-meni">O meni</a>
              </li>
              <li>
                <a href="#contact">Kontakt</a>
              </li>
            </ul>
          </nav>

          <section id="banner" className="">
            <div className="inner">
              <div id="hero-image-container">
                <img id="hero-image" src={hero} alt="" />
              </div>

              <header className="">
                <h1 className="glavni-naslov">
                  {props.data.contentfulNaslovnica.glavniNaslov}
                </h1>
              </header>
            </div>
          </section>

          <div id="main">
            <section id="psihoterapija">
              <div className="inner">
                <header className="major">
                  <h2>Zašto psihoterapija?</h2>
                </header>

                <blockquote>
                  {props.data.contentfulNaslovnica.prviCitat}
                </blockquote>
                <p>
                  <span className="image left ikona">
                    <img src={psihoterapija} alt="" />
                  </span>
                  {documentToReactComponents(
                    props.data
                      .contentfulNaslovnicaZastoPsihoterapijaRichTextNode.json
                  )}
                </p>
                <blockquote>
                  {props.data.contentfulNaslovnica.drugiCitat}
                </blockquote>
              </div>
            </section>

            <section id="moj-pristup">
              <div className="inner">
                <header className="major">
                  <h2>Moj pristup</h2>
                </header>

                <p>
                  <span className="image left ikona">
                    <img src={pristup} alt="" />
                  </span>
                  {documentToReactComponents(
                    props.data.contentfulNaslovnicaMojPristupRichTextNode.json
                  )}
                </p>
                <blockquote>
                  {props.data.contentfulNaslovnica.mojPristupCitat}
                </blockquote>
              </div>
            </section>

            <section id="o-meni">
              <div className="inner">
                <header className="major">
                  <h2>O meni</h2>
                </header>
                <p>
                  <span className="image left ikona">
                    <img src={oMeni} alt="" />
                  </span>
                  {documentToReactComponents(
                    props.data.contentfulNaslovnicaOMeniTekstRichTextNode.json
                  )}
                </p>
              </div>
            </section>
          </div>

          <section id="contact">
            <div className="inner">
              <section>
                <form
                  method="post"
                  action="https://www.flexyform.com/f/36533e65d3afb09afda766cec8010fbf7ab31efe"
                  name="contact"
                >
                  <div className="fields">
                    <div className="field half">
                      <label htmlFor="name">Ime</label>
                      <input type="text" name="name" id="name"/>
                    </div>
                    <div className="field half">
                      <label htmlFor="_reply_to">Email*</label>
                      <input type="email" name="_reply_to" id="email" required/>
                    </div>
                    <div className="field">
                      <label htmlFor="message">Poruka</label>
                      <textarea name="message" id="message" rows="6"></textarea>
                    </div>
                  </div>
                  <ul className="actions">
                    <li>
                      <input
                        type="submit"
                        value="Pošalji"
                        className="primary"
                      />
                    </li>
                    <li>
                      <input type="reset" value="Briši" />
                    </li>
                  </ul>
                </form>
              </section>
              <section className="split">
                <section>
                  <div className="contact-method">
                    <span className="icon solid alt fa-envelope"></span>
                    <h3>Email</h3>
                    <a href="mailto:marija.eva.ugrina@gmail.com   ">
                      marija.eva.ugrina@gmail.com
                    </a>
                  </div>
                </section>
                <section>
                  <div className="contact-method">
                    <span className="icon solid alt fa-phone"></span>
                    <h3>Mobitel</h3>

                    <a href="tel:+3850915364421   ">091 536 4421 </a>
                  </div>
                </section>
                <section>
                  <div className="contact-method">
                    <span className="icon solid alt fa-home"></span>
                    <h3>Ad Astra</h3>
                    <span>
                      obrt za savjetovanje <br />
                      vl. Marija Eva Ugrina Bovan
                      <br />
                      Ogrizovićeva 25
                      <br />
                      10 000 Zagreb
                      <br />
                      OIB: 02921559953
                      <br />
                      <strong>Radno vrijeme:</strong> prema dogovoru
                    </span>
                  </div>
                </section>
              </section>
            </div>
          </section>

          <footer id="footer">
            &copy; Ad Astra | {new Date().getFullYear()}
          </footer>
        </div>
      </main>
    </>
  )
}

export default IndexPage
